import React from "react"
import { useInView } from "react-intersection-observer"

const JobsBody = () => {
  const { ref, inView } = useInView()

  return (
    <section
      className="jobs--body pb-5"
      style={{
        transition: "opacity 2000ms, transform 2000ms",
        opacity: inView ? 1 : 0,
        transform: `translateY(${inView ? 0 : 100}px)`,
        transition: "all 2s",
      }}
      ref={ref}
    >
      <div className="container">
        <h3>Job Details</h3>
        <p>
          Full-time <br />
          Permanent <br />
          30 - 45 hours per week <br />
          Monday to Sunday availability required
        </p>

        <h3 className="pt-5">A bit about us</h3>
        <p>
          Cafe Kraken is an eco-centric café aiming to source as locally as
          possible and offer fresh, organic and seasonal food whilst keeping
          community at the heart of everything. If you have an interest in
          sustainability and providing quality service, this could be the place
          for you.
        </p>

        <h3 className="pt-5">Full Job Description</h3>
        <p>
          Are you someone who holds sustainability at the core of your values,
          eager to learn new skills and grow within this industry?
          <br />
          <br /> We're looking for the typical clichés: flexible, motivated, a
          team player, reliable, and honest. Some knowledge of barista skills
          and latte art would be desirable but all the training is provided. As
          long as you’re enthusiastic and friendly, we’ll sort the rest! <br />
          <br /> Mostly, we're looking for someone that wants to join a
          committed but friendly team to help us grow a community and
          sustainability focused Café; we love our locals and what we do, we
          serve excellent coffee and have a great plant-based menu. We’d love to
          welcome anyone that’s willing to get stuck into any task that needs
          doing, whether it be serving the customers, clearing tables, preparing
          food, making drinks or petting cute dogs. <br />
          <br /> We're looking for a human, not a worker slave, so... <br /> -
          the pay is competitive (up to £12 per hour depending on experience){" "}
          <br /> - the hours can be flexible around you if you're the right
          person <br /> - the management approach is understanding and personal{" "}
          <br /> - there’s room to develop skills and we encourage your growth
          (training courses, etc.) <br /> - if you want to take more on, you
          can; we're here to be part of our community so any initiatives are
          welcome <br /> - the existing team are supportive and friendly <br />
          <br />
          If this sounds like a family you’d like to be part of, please reach
          out to us at hello@cafekraken.com telling us a bit about yourself and
          why you’d be an asset to the team. <br />
          <br /> Salary: £10.50 - £12 (depending on experience)
          <br /> <br /> Benefits:
          <br /> - Company events <br /> - Discounted/free food <br />- Profit
          sharing
          <br /> - Training given on the job <br />
          <br />
          COVID-19 considerations: <br /> All staff regularly perform Lateral
          Flow tests to ensure we're working safely! You're able to wear a mask,
          and customers can do so by choice. <br />
          <br /> Ability to commute/relocate: <br /> Oxford, OX4 2NE: reliably
          commute or plan to relocate before starting work (required) <br />
          <br /> Experience: <br /> - Barista experience: 1 year (preferred){" "}
          <br /> - Hospitality: 1 year (preferred) <br /> - Customer service: 1
          year (preferred)
          <br />
          <br /> Certification: <br /> - Level 2 Food Hygiene Certification
          (preferred)
        </p>

        <h3 className="pt-5">Apply Now</h3>
        <p className="pb-5">
          Send over your CV to{" "}
          <a href="mailto:hello@cafekraken.com" className="text-atlantis">
            hello@cafekraken.com
          </a>{" "}
          with a few sentances on why you'd like to join!
        </p>
      </div>
    </section>
  )
}

export default JobsBody
