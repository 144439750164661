import React from "react"
import { motion } from "framer-motion"
import { pageFade, pageTransistion } from "../../helpers/FramerMotionAnim"
import { Container } from "react-bootstrap"
import { Parallax } from "react-scroll-parallax"

const JobsHero = () => {
  return (
    <motion.div
      initial="out"
      animate="in"
      exit="out"
      variants={pageFade}
      transition={pageTransistion}
    >
      <section className="jobs--hero">
        <Container className="text-center">
          <h1 className="d-none d-lg-block">
            <Parallax x={[-8, 4]}>Eco-Conscious Café</Parallax>{" "}
            <Parallax x={[3, -5]}>All-Rounder</Parallax>
          </h1>

          <h1 className="d-lg-none">
            Eco-Conscious Café
            <span className="text-monte-carlo"> All-Rounder </span>
          </h1>
        </Container>
      </section>
    </motion.div>
  )
}

export default JobsHero
