import React from "react"

import JobsHero from "../components/Jobs/JobsHero"
import JobsBody from "../components/Jobs/JobsBody"

import MainLayout from "../layouts/MainLayout"
import { Helmet } from "react-helmet"

const Blog = () => {
  return (
    <MainLayout>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="author" content="C.Liu" />

        <title>Jobs | Café Kraken</title>
        <meta name="description" content="Join our team." />
      </Helmet>
      <section className="jobs">
        <JobsHero></JobsHero>
        <JobsBody></JobsBody>
      </section>
    </MainLayout>
  )
}

export default Blog
